import type { IOrder } from '@goparrot/order-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import { httpParamSerializerJQLike } from '@webstore-monorepo/shared/utils/url';
import type { AxiosError } from 'axios';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { QueryKeysEnum } from './keys';

export const useVerifiedOrder = (
  merchantId?: string,
  storeId?: string,
  orderUuid?: IOrder['uuid'],
  validateOrderElements?: boolean,
  options: UseQueryOptions<false | IOrder, AxiosError, IOrder, any> = {},
): UseQueryResult<IOrder> => {
  const getVerifiedOrder = async (orderUuid: IOrder['uuid']): Promise<IOrder> => {
    return axios.get(`/api/v2/merchants/${merchantId}/stores/${storeId}/user-latest-orders/${orderUuid}`, {
      params: { validateOrderElements },
      paramsSerializer: httpParamSerializerJQLike,
    });
  };
  return useQuery([QueryKeysEnum.getPreviousOrder, orderUuid], async () => !!orderUuid && getVerifiedOrder(orderUuid), {
    enabled: !!orderUuid && !!storeId,
    staleTime: 1000,
    ...options,
  });
};
