import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const EmptyOrdersIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M8 22H28V18H8V22Z',
      'M8 30H28V26H8V30Z',
      'M0 0H4C4 1.10457 4.89543 2 6 2C7.10457 2 8 1.10457 8 0H12C12 1.10457 12.8954 2 14 2C15.1046 2 16 1.10457 16 0H20C20 1.10457 20.8954 2 22 2C23.1046 2 24 1.10457 24 0H28C28 1.10457 28.8954 2 30 2C31.1046 2 32 1.10457 32 0H36V48H32C32 46.8954 31.1046 46 30 46C28.8954 46 28 46.8954 28 48H24C24 46.8954 23.1046 46 22 46C20.8954 46 20 46.8954 20 48H16C16 46.8954 15.1046 46 14 46C12.8954 46 12 46.8954 12 48H8C8 46.8954 7.10457 46 6 46C4.89543 46 4 46.8954 4 48H0V0ZM4 42.3414C4.62556 42.1203 5.29873 42 6 42C7.53671 42 8.93848 42.5777 10 43.5278C11.0615 42.5777 12.4633 42 14 42C15.5367 42 16.9385 42.5777 18 43.5278C19.0615 42.5777 20.4633 42 22 42C23.5367 42 24.9385 42.5777 26 43.5278C27.0615 42.5777 28.4633 42 30 42C30.7013 42 31.3744 42.1203 32 42.3414V5.65859C31.3744 5.87969 30.7013 6 30 6C28.4633 6 27.0615 5.42229 26 4.47221C24.9385 5.42229 23.5367 6 22 6C20.4633 6 19.0615 5.42229 18 4.47221C16.9385 5.42229 15.5367 6 14 6C12.4633 6 11.0615 5.42229 10 4.47221C8.93849 5.42229 7.53671 6 6 6C5.29873 6 4.62556 5.87969 4 5.65859V42.3414Z',
    ],
    viewBox: '0 0 36 48',
  },
  ...BaseConfig,
  width: 48,
  height: 48,
});
